import { IntegrationConnection } from './integrations';
import { MeetingPlatform, MeetingShareData } from './meeting';
import {
  AutosaveStatusOption,
  AutosaveTimestampOption,
  UserSettings,
} from './settings';
import { UserSpaceData } from './space';
import { TeamInvitationLink, TeamSettingsData } from './team';

export enum UserFeature {
  sendIndividualMeetingLink = 'sendIndividualMeetingLink',
}

export interface UserData {
  uid: string;
  displayName: string;
  email: string;
  domain: string;
  photoURL?: string;

  provider?: string;

  autoSaveStatus: AutosaveStatusOption;
  autoSaveOptions?: {
    timestampOption: AutosaveTimestampOption;
  };
  teamDriveId?: string;
  teamDriveLink?: string;
  hasSharedLinks?: boolean;
  connections?: Record<string, IntegrationConnection>;
  shares?: MeetingShareData[];
  createdAt: number;

  stripeId?: string;

  isGoogleCalendarAddonUser?: boolean;
  isChromeExtensionUser?: boolean;

  invitationlink?: {
    id: string;
    used: number;
  };

  team: UserTeamData | null;

  settings?: UserSettings;

  features?: UserFeature[];

  meetingAppSelection?: MeetingAppSelection;

  lastMeetings?: Record<
    MeetingPlatform,
    | {
        date: number;
        count: number;
      }
    | undefined
  >;

  plan: {
    free: UserFreePlanData;
    paid?: UserPaidPlanData;
  };

  teamCouponId?: string;
  isTroubleshooting?: boolean;

  searchStatus?: SearchStatus;
  indexVersion?: number;

  bounced?: boolean;

  tag?: string; // for various experiments
  lastTeamId?: string;

  waitlists?: string[];

  subscriptions?: Record<string, string[]>;
  is_referred?: boolean;
  is_invited?: boolean;
  total_users_invited?: number;
  languagesUsed?: Record<string, number>;

  spaces?: Record<string, UserSpaceData>;

  usedWorkflows?: Record<string, { lastUsed: number }>;
}

export enum SearchStatus {
  'QUEUED' = 'QUEUED',
  'INDEXING' = 'INDEXING',
  'READY' = 'READY',
}

export enum TeamRole {
  ADMIN = 'ADMIN',
  /**
   * @deprecated not in use anymore
   */
  COLLABORATOR = 'COLLABORATOR',
}

export type UserTeamData = {
  id: string;
  link?: TeamInvitationLink;
  roles: Record<TeamRole, boolean>;
  requestedUpgrade?: boolean;
  settings?: TeamSettingsData;
  /**
   * `true` if the team has an active paid plan, `false` otherwise
   *
   * `true` does not mean that this user is using a seat
   *
   * This field is set when the user is added to the team and when team's plan data changes
   *
   * __DO NOT USE UNTIL ALL TEAMS HAVE BEEN MIGRATED__
   */
  isPaid?: boolean;
  /**
   * team plan's billing status (see `BillingStatus` GraphQL enum in API package for possible values)
   *
   * This field is set when the user is added to the team and when team's plan data changes
   *
   * __DO NOT USE UNTIL ALL TEAMS HAVE BEEN MIGRATED__
   */
  billingStatus?: string;
};

export interface MeetingAppSelection {
  zoom: boolean;
  msteams: boolean;
  webex: boolean;
  meet: boolean;
  other: boolean;
}

export interface UserPermissions {
  hasGoogleCalendarAccess: boolean;
  hasWriteGoogleCalendarAccess: boolean;
  hasGoogleDriveAccess: boolean;
}

export type UserFreePlanData = {
  as_allowance: number;
  as_used: number;
  referred: number;
  lastRenewal: number;
  prevLastRenewal?: number;
  isPreviewModeEnabled?: boolean;
  previewMeetingsCount?: number;
  lastPreviewMeetingsCount?: number;
  aiCredits?: {
    used: number;
  };
  aiCreditsAllowanceOverride?: number;
  aiCreditsBonus?: number;
};

export interface StripeFailedCharge {
  failure_code: string;
  failure_message: string;
  invoice_url: string;
  outcome: {
    network_status: string;
    reason: string;
    risk_level: string;
    seller_message: string;
    type: string;
  };
}

export type StripePaidPlanData = {
  subscriptionStatus: string;
  subscriptionPrice: number;
  subscriptionPriceId: string;
  subscriptionId: string;
  subscriptionQuantity: number;
  subscriptionRecurrenceInterval: string;
  subscriptionRecurrenceIntervalCount?: number;
  currency?: string;
  amount_off?: number;
  percent_off?: number;
  latest_failed_charge?: StripeFailedCharge;
  cancelAtPeriodEnd?: boolean;
  cancelAt?: number;
};

export type PayPalPaidPlanData = {
  subscriptionId: string;
  subscriptionStatus: string;
  subscriptionPrice?: number;
  payPal: true;
  subscriptionPriceId?: string;
  nextBillingTime?: number;
  cancelAtPeriodEnd?: boolean;
};

export type TeamPaidPlanData = {
  team: true;
};

export type UserPaidPlanData =
  | StripePaidPlanData
  | PayPalPaidPlanData
  | TeamPaidPlanData;

export enum InviteeStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
}

export type InviteeData = {
  uid: string;
  status?: InviteeStatus;

  email: string;
  sentAt?: number;

  name?: string;
  acceptedAt?: number;
  fromLink?: boolean;
};

export const CurrentMeetingsIndexVersion = 17;
