type FirebaseTimestamp = {
  toMillis: () => number;
};

export type WorkflowDocument = {
  id: string;
  name: string;
  definition: WorkflowDefinition;
  isArchived?: boolean;
  nodeCounter: number;
  createdBy: string;
  createdAt: FirebaseTimestamp;
  updatedAt?: FirebaseTimestamp;
  usage?: {
    runs?: number;
    duplications?: number;
    templates?: number;
  };
  tactiq?: boolean;
  publishedId?: string;
  publishedFrom?: string;
};

export type WorkflowNode = {
  id: string;
  type?: string;
  data: unknown;
  position: {
    x: number;
    y: number;
  };
  width?: number;
  height?: number;
};

export type RunMeetingKitNode = WorkflowNode & {
  data: {
    applyToMeeting: boolean;
    meetingKitItemId: string;
    meetingKitId: string;
    promptType: 'system' | 'used' | 'explore';
  };
};

export type CustomPromptNode = WorkflowNode & {
  data: {
    prompt: string;
    saveToMeeting: boolean;
    aiOutputLanguage: string;
    includeTranscriptContext: boolean;
  };
};

export type TemplateNode = WorkflowNode & {
  data: {
    template: string;
    title: string;
    saveToMeeting: boolean;
  };
};

export type SaveToSpaceNode = WorkflowNode & {
  data: {
    space: string;
  };
};

export type ConfirmationNode = WorkflowNode & {
  data: {
    type: string;
    prompt: string;
  };
};

export type ConfirmationResult = {
  isYes: boolean;
  content: string;
};

export type ConditionNode = WorkflowNode & {
  data: {
    condition: string;
  };
};

export type ShareNode = WorkflowNode & {
  data: {
    type: 'slack' | 'email';
    bodyTemplate?: string;
    slack?: {
      id: string;
      name: string;
      scope: string;
      connectionKey: string;
    };
    email?: {
      subject: string;
    };
  };
};

export type WorkflowEdge = {
  id: string;
  source: string;
  target: string;
  sourceHandle?: string;
};

export type WorkflowDefinition = {
  edges: WorkflowEdge[];
  nodes: WorkflowNode[];
};

export enum WorkflowStatus {
  NOT_STARTED = 'NOT_STARTED',
  PENDING = 'PENDING',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION',
  COMPLETE = 'COMPLETE',
  SKIPPED = 'SKIPPED',
  FAILED = 'FAILED',
  TIMED_OUT = 'TIMED_OUT',
}

export type WorkflowExecutionData = {
  id: string;
  workflowId: string;
  workflowName: string;
  meetingId: string;
  definition: WorkflowDefinition;
  status: WorkflowStatus;
  nodeData: Record<
    string,
    {
      id: string;
      status: WorkflowStatus;
      startedAt?: FirebaseTimestamp;
      completedAt?: FirebaseTimestamp;
      output?: any;
      error?: {
        message: string;
      };
    }
  >;
  uid: string;
  createdAt: FirebaseTimestamp;
  updatedAt?: FirebaseTimestamp;
  templateContext: WorkflowTemplateContext;
  executionCounter: number;
};

/**
 * A slim version of users and meeting given to liquid templates
 * We wan't to add things sparingly, because once they are used in templates we
 * cant really remove them.
 */
export type WorkflowTemplateContext = {
  meeting: {
    title: string;
    url: string;
    participants: Array<{ name: string; email?: string }>;
  };
  user: {
    name: string;
    email: string;
  };
};
