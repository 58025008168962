import React, {
  Fragment,
  ReactElement,
  ReactNode,
  useRef,
  useState,
} from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  useTypeahead,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
} from '@floating-ui/react';
import { cx } from '../../helpers/utils';
import { Check, ChevronDown } from 'lucide-react';
import { FormattedMessage } from 'react-intl';

/**
 * A single select dropdown for when you know all the possible items
 */
export function Select<T>({
  value,
  options,
  onChange,
  placeholder,
  disabled,
  variant = 'default',
  full,
  onOpenChange,
  root,
}: {
  variant?: 'default' | 'naked';
  value: T | null;
  disabled?: boolean;
  full?: boolean;
  onChange: (next: T) => void;
  options: Array<{
    value: T;
    label: string;
    icon?: ReactNode;
    divider?: 'top' | 'bottom';
  }>;
  placeholder?: ReactNode;
  onOpenChange?: (open: boolean) => void;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
}): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(
    options.findIndex((o) => o.value === value)
  );

  const handleOpenChange = (isOpen: boolean) => {
    setIsOpen(isOpen);
    onOpenChange?.(isOpen);
  };

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement: 'bottom-start',
    open: isOpen,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);
  const listContentRef = useRef(options.map((ii) => ii.label));
  const isTypingRef = useRef(false);

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [
      useClick(context, { event: 'mousedown' }),
      useDismiss(context),
      useRole(context, { role: 'listbox' }),
      useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
        loop: true,
      }),
      useTypeahead(context, {
        listRef: listContentRef,
        activeIndex,
        selectedIndex,
        onMatch: isOpen ? setActiveIndex : setSelectedIndex,
        onTypingChange(isTyping) {
          isTypingRef.current = isTyping;
        },
      }),
    ]
  );

  const handleSelect = (index: number) => {
    setSelectedIndex(index);
    handleOpenChange(false);
    onChange(options[index].value);
  };

  const selected = selectedIndex === null ? null : options[selectedIndex];

  return (
    <>
      <div
        tabIndex={0}
        ref={refs.setReference}
        aria-autocomplete="none"
        {...getReferenceProps()}
        className={cx(
          'inline-flex min-h-9 cursor-pointer items-center gap-1 truncate rounded-input border border-slate-200 px-2 py-1 text-sm font-medium shadow-sm',
          disabled
            ? 'pointer-events-none cursor-default border-slate-200 bg-slate-100 text-slate-400'
            : 'border-slate-200 hover:border-slate-400/50 hover:bg-slate-25',
          variant === 'naked' ? 'border-transparent' : '',
          full ? 'w-full' : 'max-w-64',
          'focus:outline-none focus-visible:border-brand-500 focus-visible:ring-1 focus-visible:ring-brand-500'
        )}
      >
        {selected?.icon && <div className="flex-shrink-0">{selected.icon}</div>}
        <span className="truncate">
          {selected?.label ?? (
            <span className="text-slate-400">
              {placeholder ?? (
                <FormattedMessage defaultMessage="Select" id="kQAf2d" />
              )}
            </span>
          )}
        </span>
        <span className="ml-auto flex-shrink-0">
          <ChevronDown size="1rem" />
        </span>
      </div>
      {isOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <FloatingPortal root={root}>
            <div
              ref={refs.setFloating}
              {...getFloatingProps()}
              style={floatingStyles}
              className="z-Menu flex max-h-[30vh] max-w-64 flex-col gap-1 overflow-auto rounded-lg border border-slate-200 bg-white p-2 shadow-lg shadow-slate-600/20 focus:outline-none"
            >
              {options.map((o, i) => (
                <Fragment key={String(o.value)}>
                  {o.divider === 'top' && <hr className="-mx-2 my-1" />}
                  <div
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={i === selectedIndex && i === activeIndex}
                    {...getItemProps({
                      onClick: () => handleSelect(i),
                      onKeyDown: (event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          handleSelect(i);
                        }

                        if (event.key === ' ' && !isTypingRef.current) {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                    className={cx(
                      'flex flex-shrink-0 cursor-pointer items-center gap-2 overflow-hidden truncate rounded px-2 py-1 text-left text-sm',
                      i === selectedIndex
                        ? 'bg-brand-500 text-white focus:bg-brand-500'
                        : 'bg-white',
                      'disabled:cursor-default',
                      'focus:bg-brand-100 focus:outline-none',
                      o.divider === 'top' ? 'border-t-slate-300' : '',
                      o.divider === 'bottom' ? 'border-b-slate-300' : ''
                    )}
                  >
                    <span aria-hidden className="size-4 flex-shrink-0">
                      {i === selectedIndex && <Check size="1rem" />}
                    </span>
                    {o.icon && <div className="flex-shrink-0">{o.icon}</div>}
                    <span className="truncate">{o.label}</span>
                  </div>
                  {o.divider === 'bottom' && <hr className="-mx-2 my-1" />}
                </Fragment>
              ))}
            </div>
          </FloatingPortal>
        </FloatingFocusManager>
      )}
    </>
  );
}
