import { useMutation } from '@apollo/client';
import { delay } from '@tactiq/model';
import {
  ChevronDown,
  ChevronUp,
  Settings2,
  User2,
  UserPlus,
  Wallet2,
} from 'lucide-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/buttons';
import { AddTeamMemberDocument, Team } from '../../../graphql/operations';
import {
  TierPricingDialogSource,
  trackWebEvent,
} from '../../../helpers/analytics';
import { kBilling, kSettingsTeam, kTeam } from '../../../helpers/routes';
import { cx } from '../../../helpers/utils';
import { gotTeam } from '../../../redux/modules/user';
import { selectUserTier } from '../../../redux/selectors';
import { TierPricingDialog } from '../../Credits/TierPricing/TierPricingDialog';
import { AddMembersModal } from '../../Teams/Team/AddMembers';
import { NavMenuItem } from './NavMenuItem';
import { Tooltip } from '../../../components/Tooltip';

const InviteTeammembersButtonClasses =
  'group rounded-es-card rounded-ee-card bg-white hover:bg-slate-50 border-t border-slate-200';

const InviteTeammembersButtonOpenClasses =
  'flex justify-center items-center px-2 pb-2';

export const TeamMenu: React.FC<{
  team: Team;
  pageName: string;
  isMobileMenuVisible: boolean;
}> = ({ team, pageName, isMobileMenuVisible }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isAddMemberModalOpen, setIsAddMemberModalOpen] =
    React.useState<boolean>(false);

  const [showTierPricingDialog, setShowTierPricingDialog] =
    React.useState<boolean>(false);
  const userTier = useSelector(selectUserTier);

  const dispatch = useDispatch();

  const [addTeamMember] = useMutation(AddTeamMemberDocument);

  const onAdd = React.useCallback(
    async (emails: string[]) => {
      for (const memberEmail of emails) {
        dispatch(
          gotTeam(
            (
              await addTeamMember({
                variables: { input: { memberEmail } },
              })
            ).data?.AddTeamMember
          )
        );
        await delay(1100);
      }

      trackWebEvent('Team - Add Members', {
        count: emails.length,
      });

      setIsAddMemberModalOpen(false);
    },
    [dispatch, addTeamMember]
  );

  return (
    <div
      className={cx(
        'flex-col gap-2 rounded-card border border-slate-200 shadow-sm',
        isMobileMenuVisible ? 'flex' : 'hidden md:flex'
      )}
    >
      <div className="mb-2 px-4 pt-2">
        <Button
          onClick={() => setIsOpen(!isOpen)}
          variant="text"
          fullWidth
          size="text"
        >
          <div className="flex w-full flex-row justify-between">
            <p className="truncate text-sm text-slate-700">
              {team.displayName}
            </p>
            {isOpen ? (
              <ChevronUp
                strokeWidth={2}
                size={20}
                className="text-slate-400"
                aria-hidden="true"
              />
            ) : (
              <ChevronDown
                strokeWidth={2}
                size={20}
                className="text-slate-400"
                aria-hidden="true"
              />
            )}
          </div>
        </Button>
        <p className="text-[12px] text-slate-600">
          {team.members.length === 1 ? (
            <FormattedMessage
              defaultMessage="{memberCount} member"
              id="UVtjSu"
              values={{
                memberCount: team.members.length,
              }}
            />
          ) : (
            <FormattedMessage
              defaultMessage="{memberCount} members"
              id="KCM7q4"
              values={{
                memberCount: team.members.length,
              }}
            />
          )}
        </p>
      </div>
      {isOpen ? (
        <div className="mb-2 border-y border-slate-200 py-2">
          <NavMenuItem
            currentPage={pageName}
            href={kTeam}
            icon={User2}
            label="Users"
          />
          <NavMenuItem
            currentPage={pageName}
            href={kSettingsTeam}
            icon={Settings2}
            label="Team Settings"
          />
          <NavMenuItem
            currentPage={pageName}
            href={kBilling}
            icon={Wallet2}
            label="Billing"
          />
        </div>
      ) : null}

      <div
        className={
          isOpen
            ? InviteTeammembersButtonOpenClasses
            : InviteTeammembersButtonClasses
        }
      >
        <Tooltip
          arrow
          placement="right"
          title={
            <FormattedMessage defaultMessage="Invite teammates" id="8PIpCw" />
          }
        >
          <Button
            variant={isOpen ? 'filled' : 'text'}
            startIcon={<UserPlus className="h-4 w-4" />}
            size="small"
            fullWidth
            onClick={() => setIsAddMemberModalOpen(true)}
          >
            <p className="truncate">
              <FormattedMessage defaultMessage="Invite teammates" id="8PIpCw" />
            </p>
          </Button>
        </Tooltip>
      </div>
      {isAddMemberModalOpen && (
        <AddMembersModal
          team={team}
          open={isAddMemberModalOpen}
          onAdd={onAdd}
          onClose={() => setIsAddMemberModalOpen(false)}
        />
      )}
      {showTierPricingDialog ? (
        <TierPricingDialog
          userTier={userTier}
          teamTier={team?.tier}
          source={TierPricingDialogSource.TEAM_MENU}
          onClose={() => setShowTierPricingDialog(false)}
          teamSpecific
        />
      ) : null}
    </div>
  );
};
